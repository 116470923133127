import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const CookiepolicyPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <Helmet>
      <title>Swiftfox | Cookie Policy</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}
                >
                  Cookie Policy
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}
                >
                  Swiftfox uses cookies to enhance your experience, analyze our traffic, and improve and personalize our services. By using our website, you consent to our use of cookies in accordance with this policy.{" "}
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>

    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
         <p>
         What are cookies?
<br></br>

Cookies are small text files stored on your device when websites are loaded in a browser. They are widely used to remember your preferences, either for a single visit or for multiple repeat visits.
<br></br>
<br></br>

Types of cookies we use:
<br></br>
<ul>
<li>Necessary cookies: These are essential for you to browse the website and use its features.</li>
<li>Preference cookies: These cookies remember your preferences and settings.</li>
<li>Statistics cookies: These help us understand how you interact with our website, collecting and reporting information anonymously.</li>
<li>Marketing cookies: These are used to track visitors across websites to allow publishers to display relevant and engaging ads.</li>
</ul>

Managing cookies: 
<br></br>
You can manage cookies through your browser settings at any time. However, please note that this might affect the functionality and your experience of our site.
<br></br>
<br></br>

Changes to our Cookie Policy: 
<br></br>

We may update this policy from time to time in response to changing legal, technical, or business developments. When we update our Cookie Policy, we will take appropriate measures to inform you.
<br></br>
<br></br>

Contact: 
<br></br>

For any questions about how we use cookies, please contact us at support@swiftfox.co.uk.
         </p>
        </div>
      </div>
    </div>
  </div>
);

CookiepolicyPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const CookiepolicyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CookiepolicyPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

CookiepolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CookiepolicyPage;

export const pageQuery = graphql`
  query CookiepolicyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
